@import '../../styles/theme.sass'


.event
  h4,
  p
    margin: 0


.eventDivider
  border-bottom: 1px dotted #cccccc
  padding: 0 0 10px 0
  margin: 0 0 10px 0