@import '../../styles/theme.sass'


.splashContainer
  background-color: $brandyellow
  display: flex,
  flex-direction: column,
  justify-content: center,
  align-items: center

  .splash
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .bee
      width: 100%

    .splashButton
      background-color: #ffffff
      font-size: 30px
      line-height: 30px
      font-weight: bold
