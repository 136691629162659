
////////////////////////////////////////////////////////////////////////////////

EVERYTHING

*
  font-family: arial, sans-serif
  box-sizing: border-box
  padding: 0
  margin: 0


////////////////////////////////////////////////////////////////////////////////

COLOURS

$brandyellow: #ffc700


////////////////////////////////////////////////////////////////////////////////

DEFAULTS

$width: 600px
$fullpadding: 30px
$halfpadding: 15px


////////////////////////////////////////////////////////////////////////////////

TEXT

h1, h2, h3, h4, h5, p
  margin-bottom: $halfpadding



////////////////////////////////////////////////////////////////////////////////

STRUCTURE

.header, .footer, .content
  padding: $fullpadding
  display: flex
  flex-direction: column
  align-items: center

.content
  flex: 1

.container
  width: 100%
  min-width: 300px
  max-width: $width

.columns
  display: flex
  flex-direction: row
  align-items: flex-start
  justify-content: flex-start

  @media only screen and (max-width: $width)
    flex-direction: column

.card
  padding: 20px
  border: 1px solid #cccccc
  border-radius: 5px

.fullwidthgap
  gap: $fullpadding

.gap10
  gap: 10px

.gap20
  gap: 20px

.textfield
  margin: 10px 0
  padding: 10px
  border-radius: 5px
  border: 1px solid #cccccc

.fullwidth
  width: 100%

.fullwidthblock
  width: 100%
  display: block

////////////////////////////////////////////////////////////////////////////////

BUTTONS

.button
  border: none
  margin: 0
  display: inline-block
  padding: 10px 20px
  border-radius: 5px
  background-color: #bdbdbd
  color: #000000
  cursor: pointer
  text-decoration: none
  font-size: 16px
  line-height:17px

  &:disabled
    cursor: default
    opacity: 0.6

  &:hover
    background-color: #a3a3a3

  &.primary
    background-color: #ffbb00

  &.primary:hover
    background-color: #cf9800

  &.white
    background-color: #ffffff

  &.white:hover
    background-color: #dedede
